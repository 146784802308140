import { useState } from 'react'
import styled from 'styled-components'
import Arrow from '../assets/svg/arrow-right.svg'
import { useIntl } from '../utils/IntlContext'
import { getLocalePathPrefix, getLocalizedRootUrlPrefix, urlPathJoin } from '../utils/locales'
import { legacyBreakpoints } from '../utils/media'
import Link from './Link'

const Container = styled.div`
  padding: 0 var(--spacing-md);
`

const ResourceNav = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--color-light-outline);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 30px;
`

const ResourceNavItem = styled.li`
  padding: var(--spacing-xsm);
  margin: 0;
  font-size: 20px;
  letter-spacing: 0;
  cursor: pointer;
  flex-grow: 1;
  transition: all 200ms;
  border-bottom: 3px solid ${({ $isActive }) => ($isActive ? 'var(--color-primary)' : 'white')};

  a {
    color: ${({ $isActive }) => ($isActive ? 'var(--color-typography-primary)' : 'var(--color-typography-medium)')};
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    a {
      font-size: 14px;
      line-height: 20px;
    }
  }
`

const ResourceContainer = styled.div`
  background: white;
  display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
  padding: 0 30px;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    padding: 0;
  }
`

const ArrowContainer = styled.div`
  padding: 0;
`

const LinkContainer = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid var(--color-light-outline);

  span {
    color: var(--color-typography-medium) !important;
    line-height: 30px;
  }

  &:last-of-type {
    border: none;
  }

  ${ArrowContainer} {
    transition: all 200ms;

    svg {
      width: 10px;
    }
  }

  &:hover {
    span {
      color: var(--color-primary) !important;
    }

    ${ArrowContainer} {
      transform: translateX(10px);
    }
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    padding: 20px 0;
    span {
      font-size: 15px;
      line-height: 20px;
    }
  }
`

const Item = ({ title, url }) => (
  <LinkContainer to={url}>
    <span>{title}</span>
    <ArrowContainer>
      <Arrow />
    </ArrowContainer>
  </LinkContainer>
)

const ResourceList = ({ resources, isActive }) => {
  const { locale } = useIntl()

  return (
    <ResourceContainer $isActive={isActive}>
      {resources.map((page) => {
        switch (page.__typename) {
          case 'ContentfulBlogPost': {
            if (!page.link_title && !page.title) {
              return null
            }

            if (!page.slug) {
              return null
            }

            return (
              <Item
                key={page.id}
                title={page.link_title || page.title}
                url={urlPathJoin(getLocalizedRootUrlPrefix(page.parentPage, locale), page.slug)}
              />
            )
          }
          case 'ContentfulContentPage':
          case 'ContentfulPage': {
            if (!page.link_title || !page.slug) {
              return null
            }

            return (
              <Item
                key={page.id}
                title={page.link_title}
                url={
                  page.parentPage
                    ? urlPathJoin(getLocalizedRootUrlPrefix(page.parentPage, locale), page.slug)
                    : urlPathJoin(getLocalePathPrefix(locale), page.slug)
                }
              />
            )
          }
          case 'ContentfulLink': {
            if (!page.text?.value || !page.url) {
              return null
            }

            return (
              <Item
                key={page.id}
                title={page.text.value}
                url={page}
              />
            )
          }
          default:
            throw new Error(`Unsupported resource type ${page.__typename}.`)
        }
      })}
    </ResourceContainer>
  )
}

const KnowledgeCenterResources = ({ resourceGroups }) => {
  const { locale } = useIntl()
  const [activeGroupId, setActiveGroupId] = useState(
    resourceGroups.find((group) => locale === 'en-US' || !group.english_only).id,
  )

  const filteredResourceGroups = resourceGroups.filter((group) => locale === 'en-US' || !group.english_only)

  return (
    <Container>
      <ResourceNav>
        {filteredResourceGroups.map((group) => (
          <ResourceNavItem
            key={group.id}
            $isActive={activeGroupId === group.id}
            onClick={() => setActiveGroupId(group.id)}>
            <Link to={group.slug}>{group.title}</Link>
          </ResourceNavItem>
        ))}
      </ResourceNav>
      {filteredResourceGroups.map((group) => (
        <ResourceList
          key={group.id}
          resources={group.resources}
          isActive={activeGroupId === group.id}
        />
      ))}
    </Container>
  )
}

export default KnowledgeCenterResources
