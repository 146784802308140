import styled from 'styled-components'
import { useIntl } from '../utils/IntlContext'
import { legacyBreakpoints } from '../utils/media'
import KnowledgeCenterSearchField from './KnowledgeCenterSearchField'

const SearchWrapper = styled.div`
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
  padding: 220px 20px 120px 20px;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    padding: 140px 20px 80px 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    h1 {
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  span {
    margin-top: 20px;
    display: block;
    font-size: 12px;
  }
`

const KnowledgeCenterSearch = ({ list }) => {
  const { t } = useIntl()

  const subtext = t('knowledge_center_search_subtext')

  return (
    <SearchWrapper>
      <h1>{t('knowledge_center_search_title')}</h1>
      <p>{t('knowledge_center_search_description')}</p>
      <KnowledgeCenterSearchField list={list} />
      {subtext && <span>{subtext}</span>}
    </SearchWrapper>
  )
}

export default KnowledgeCenterSearch
