import { navigate } from 'gatsby'
import { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import styled from 'styled-components'
import SearchIcon from '../assets/img/search.png'
import { useIntl } from '../utils/IntlContext'
import { isGatsbyRouterUrl } from '../utils/locales'
import Link from './Link'

const SearchContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 100%;
    font-size: 16px;
    border-radius: var(--border-radius);
    border: none;
    background: url(${SearchIcon}) no-repeat scroll 14px 14px;
    padding: 14px 14px 14px 44px;
    background-color: white;
    box-shadow: var(--box-shadow-black-small);

    &:focus {
      box-shadow: var(--box-shadow-black-medium);
    }
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 60px;
    text-align: left;
    box-shadow: var(--box-shadow-black-medium);
    font-size: 16px;
    z-index: 2;
    border-radius: var(--border-radius);
    width: 100%;
    margin: 0 auto;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    background-color: white;
    border-radius: var(--border-radius);

    a {
      text-align: left;
      margin-bottom: 0;
      text-transform: capitalize;
      color: var(--color-typography-medium);
    }
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 15px;
    margin: 0;

    &:first-of-type {
      padding-top: 15px;
    }

    &:last-of-type {
      padding-bottom: 15px;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: var(--color-background-light);

    a {
      color: var(--color-primary);
    }
  }
`

const KnowledgeCenterSearchField = ({ list, placeholder }) => {
  const { t } = useIntl()

  const [value, setValue] = useState('')
  const [items, setItems] = useState([])
  const limitListCount = 5

  const getItems = (value) => {
    const inputValue = value.trim().toLowerCase()

    return inputValue.length === 0
      ? []
      : list.filter((item) => item.title?.toLowerCase().startsWith(inputValue)).slice(0, limitListCount)
  }

  const getItemValue = (item) => item.title

  const renderItem = (item) => <Link to={item.url}>{item.title}</Link>

  const inputProps = {
    placeholder: placeholder || t('search'),
    value,
    onChange: (_event, { newValue }) => {
      setValue(newValue)
    },
  }

  return (
    <SearchContainer>
      <Autosuggest
        suggestions={items}
        onSuggestionsFetchRequested={({ value }) => setItems(getItems(value))}
        onSuggestionsClearRequested={() => setItems([])}
        onSuggestionSelected={(event, { suggestion: { url } }) => {
          if (isGatsbyRouterUrl(url)) {
            navigate(url)
          } else {
            location.href = url
          }
        }}
        getSuggestionValue={getItemValue}
        renderSuggestion={renderItem}
        inputProps={inputProps}
      />
    </SearchContainer>
  )
}

export default KnowledgeCenterSearchField
