import loadable from '@loadable/component'
import { getImage } from 'gatsby-plugin-image'
import { css } from 'styled-components'
import ConditionalWrapper from './ConditionalWrapper'
import CTA from './CTA'
import MarkdownContent from './MarkdownContent'
import Section from './Section'
import Wrapper from './Wrapper'

const PageSectionBannerTextBackground = loadable(() => import('./PageSectionBannerTextBackground'))
const PageSectionContactForm = loadable(() => import('./PageSectionContactForm'))
const PageSectionFAQ = loadable(() => import('./PageSectionFAQ'))
const PageSectionIconSet = loadable(() => import('./PageSectionIconSet'))
const PageSectionMainCTA = loadable(() => import('./PageSectionMainCTA'))
const PageSectionResumeTips = loadable(() => import('./PageSectionResumeTips'))
const PageSectionTeamMembers = loadable(() => import('./PageSectionTeamMembers'))
const PageSectionTextImage = loadable(() => import('./PageSectionTextImage'))
const PageSectionTestimonials = loadable(() => import('./PageSectionTestimonials'))

const PageSection = ({ contentfulId, successStoriesPath, sections, location }) => {
  const MappedComponent = ({ section, component }) => {
    switch (component.__typename) {
      case 'ContentfulComponentTextImage': {
        const { title, subtitle, long_description, image, primary_cta, alignment, image_alt } = component
        return (
          <PageSectionTextImage
            title={title}
            subtitle={subtitle}
            description={long_description}
            primaryCta={primary_cta}
            alignment={alignment}
            imageAlt={image_alt}
            image={image}
          />
        )
      }
      case 'ContentfulComponentResumeTips': {
        return <PageSectionResumeTips section={component} />
      }
      case 'ContentfulComponentIconSet':
        return (
          <PageSectionIconSet
            icons={component.icon_text_item}
            backgroundColor={component.background_color}
            maxColumns={component.max_grid_columns}
            centerIcons={component.center_icons}
            displayConnectingDashes={component.displayConnectingDashes}
          />
        )
      case 'ContentfulComponentTestimonials':
        return (
          <PageSectionTestimonials
            testimonials={component.testimonials}
            disabled={contentfulId === '35SjrERFSPauSTpENszW1i'}
            displayAsSlider={component.displayAsSlider}
            successStoriesPath={successStoriesPath}
          />
        )
      case 'ContentfulComponentMainCta':
        return (
          <PageSectionMainCTA
            theme={section.background_color}
            title={component.title}
            description={component.description}
            cta={component.cta}
            image={component.image}
            imageAlt={component.image_alt}
          />
        )
      case 'ContentfulComponentFaq':
        return (
          <PageSectionFAQ
            title={component.title}
            faqs={component.faqs}
          />
        )
      case 'ContentfulComponentContentBlock':
        return (
          <MarkdownContent
            el={component.content?.childMarkdownRemark.htmlAst}
            images={component.content?.childMarkdownRemark.images}
            additionalStyles={css`
              text-align: ${component.textAlignment?.toLowerCase() ?? 'center'};
            `}
          />
        )
      case 'ContentfulComponentTeamMembers':
        return <PageSectionTeamMembers members={component.team_members} />
      case 'ContentfulComponentImageHubspotForm':
        return (
          <PageSectionContactForm
            formTitle={component.formTitle}
            formSubtext={component.formSubtext}
            formCompleteSubtext={component.formCompleteSubtext}
            formCompleteTitle={component.formCompleteTitle}
            image={component.image}
            pageTitle={component.title}
            pageUri={location.hostname + location.pathname}
            hubspot={component.hubspot}
          />
        )
      case 'ContentfulBannerTextBackground':
        return <PageSectionBannerTextBackground banner={component} />
      default:
        return null
    }
  }

  if (!sections) {
    return null
  }

  return sections.map((section) => {
    const title = section.title && <h2>{section.title}</h2>
    const description = section.description && <p>{section.description}</p>
    const components = section.component
      ?.map((component) => (
        <MappedComponent
          key={component.id}
          section={section}
          component={component}
        />
      ))
      .filter(Boolean)
    const useWrapper = !section.component?.some(
      (component) => component.__typename === 'ContentfulBannerTextBackground',
    )
    const link = section.link?.text && (
      <CTA
        style={{ marginTop: '50px' }}
        to={section.link}>
        {section.link.text.value}
      </CTA>
    )

    // We must not render sections that do not contain at least one element
    if (!title && !description && (!components || components.length === 0) && !link) {
      return null
    }

    return (
      <Section
        key={section.contentful_id}
        id={section.contentful_id}
        className="section"
        align="center"
        backgroundImage={getImage(section.background_image?.localFile)}
        bgColor={section.background_color}
        hasComponent={section.component}
        hasDescription={section.description}
        primaryButtons={section.primaryButtons}>
        <ConditionalWrapper
          condition={useWrapper}
          wrapper={(children) => <Wrapper>{children}</Wrapper>}>
          {title}
          {description}
          {components}
          {link}
        </ConditionalWrapper>
      </Section>
    )
  })
}

export default PageSection
