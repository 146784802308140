import { graphql } from 'gatsby'
import GatsbyHead from '../components/GatsbyHead'
import KnowledgeCenterResources from '../components/KnowledgeCenterResources'
import KnowledgeCenterSearch from '../components/KnowledgeCenterSearch'
import LayoutWrapper from '../components/LayoutWrapper'
import PageBannerContainer from '../components/PageBannerContainer'
import PageSection from '../components/PageSection'
import Section from '../components/Section'
import Wrapper from '../components/Wrapper'
import { useIntl } from '../utils/IntlContext'
import { createUrl, getLocalePathPrefix, getLocalizedRootUrlPrefix, urlPathJoin } from '../utils/locales'

export const query = graphql`
  query KnowledgeCenter($contentful_id: String!, $locale: String!) {
    contentfulReactPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      node_locale
      languages
      meta_title
      meta_description
      sections {
        ...PageSection
      }
    }
    resourceGroups: allContentfulResourceGroup(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          title
          english_only
          resources {
            __typename
            ... on Node {
              ... on ContentfulContentPage {
                id
                link_title
                parentPage {
                  ... on ContentfulContentPage {
                    slug
                  }
                  ... on ContentfulPage {
                    slug
                  }
                  ... on ContentfulReactPage {
                    slug
                  }
                }
                slug
              }
              ... on ContentfulPage {
                id
                parentPage {
                  ... on ContentfulContentPage {
                    slug
                  }
                  ... on ContentfulPage {
                    slug
                  }
                  ... on ContentfulReactPage {
                    slug
                  }
                }
                link_title
                slug
              }
              ... on ContentfulLink {
                ...Link
              }
              ... on ContentfulBlogPost {
                id
                parentPage {
                  slug
                }
                slug
                link_title
                title
              }
            }
          }
        }
      }
    }
    successStoriesPage: contentfulReactPage(
      contentful_id: { eq: "35SjrERFSPauSTpENszW1i" }
      node_locale: { eq: $locale }
    ) {
      slug
    }
  }
`

const KnowledgeCenter = ({
  pageContext: { translatedPages },
  data: {
    contentfulReactPage: { contentful_id, sections },
    resourceGroups,
    successStoriesPage,
  },
}) => {
  const { locale } = useIntl()

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <PageBannerContainer $lightStyling>
        <KnowledgeCenterSearch
          list={resourceGroups.edges
            .map((edge) => edge.node)
            .flatMap((group) =>
              group.resources.map((resource) => {
                switch (resource.__typename) {
                  case 'ContentfulBlogPost':
                    if (!resource.link_title && !resource.title) {
                      return null
                    }

                    if (!resource.slug) {
                      return null
                    }

                    return {
                      title: resource.link_title || resource.title,
                      url: urlPathJoin(getLocalizedRootUrlPrefix(resource.parentPage, locale), resource.slug),
                    }
                  case 'ContentfulLink':
                    if (!resource.text?.value || !resource.url) {
                      return null
                    }

                    return {
                      title: resource.text.value,
                      url: createUrl(resource.url, locale),
                    }
                  case 'ContentfulContentPage':
                  case 'ContentfulPage':
                    if (!resource.link_title || !resource.slug) {
                      return null
                    }

                    return {
                      title: resource.link_title,
                      url: resource.parentPage
                        ? urlPathJoin(getLocalizedRootUrlPrefix(resource.parentPage, locale), resource.slug)
                        : urlPathJoin(getLocalePathPrefix(locale), resource.slug),
                    }
                  default:
                    throw new Error(`Unexpected resource type ${resource.__typename}`)
                }
              }),
            )
            .filter(Boolean)}
        />
      </PageBannerContainer>
      <Section
        className="section"
        align="center"
        hasComponent>
        <Wrapper>
          <KnowledgeCenterResources resourceGroups={resourceGroups.edges.map((node) => node.node)} />
        </Wrapper>
      </Section>
      <PageSection
        contentfulId={contentful_id}
        successStoriesPath={getLocalizedRootUrlPrefix(successStoriesPage, locale)}
        sections={sections}
      />
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulReactPage: { meta_title, meta_description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default KnowledgeCenter
